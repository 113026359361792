<template>
  <div>
    <!--
		mask是loading的背景 v-show控制loading显示消失
	-->
    <div class="mask" v-show="isShow">
      <!-- <div class="loading"></div> -->
      <!-- <div class="loadingio-spinner-bean-eater-kxau46gxv1g">
        <div class="ldio-jfqc7hmm0cb">
          <div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div> -->


      <div class="container loading6">
        <div class="shape shape1"></div>
        <div class="shape shape2"></div>
        <div class="shape shape3"></div>
        <div class="shape shape4"></div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {
      // loading默认不显示
      isShow: false
    }
  }
}
</script>
<style lang="scss">
// 定义动画 控制 loading旋转
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.mask {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  .loading {
    width: 30px;
    height: 30px;
    border: 6px solid rgb(219, 140, 13);
    border-radius: 21px;
    border-left-color: transparent;
    animation: rotate 500ms infinite;
  }
}

@keyframes ldio-jfqc7hmm0cb-1 {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-45deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes ldio-jfqc7hmm0cb-2 {
  0% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(225deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
.ldio-jfqc7hmm0cb > div:nth-child(2) {
  transform: translate(-15px, 0);
}
.ldio-jfqc7hmm0cb > div:nth-child(2) div {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 60px;
  height: 30px;
  border-radius: 60px 60px 0 0;
  background: #f8b26a;
  animation: ldio-jfqc7hmm0cb-1 1s linear infinite;
  transform-origin: 30px 30px;
}
.ldio-jfqc7hmm0cb > div:nth-child(2) div:nth-child(2) {
  animation: ldio-jfqc7hmm0cb-2 1s linear infinite;
}
.ldio-jfqc7hmm0cb > div:nth-child(2) div:nth-child(3) {
  transform: rotate(-90deg);
  animation: none;
}
@keyframes ldio-jfqc7hmm0cb-3 {
  0% {
    transform: translate(95px, 0);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: translate(35px, 0);
    opacity: 1;
  }
}
.ldio-jfqc7hmm0cb > div:nth-child(1) {
  display: block;
}
.ldio-jfqc7hmm0cb > div:nth-child(1) div {
  position: absolute;
  top: 46px;
  left: -4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #e15b64;
  animation: ldio-jfqc7hmm0cb-3 1s linear infinite;
}
.ldio-jfqc7hmm0cb > div:nth-child(1) div:nth-child(1) {
  animation-delay: -0.67s;
}
.ldio-jfqc7hmm0cb > div:nth-child(1) div:nth-child(2) {
  animation-delay: -0.33s;
}
.ldio-jfqc7hmm0cb > div:nth-child(1) div:nth-child(3) {
  animation-delay: 0s;
}
.loadingio-spinner-bean-eater-kxau46gxv1g {
  width: 100px;
  height: 100px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
}
.ldio-jfqc7hmm0cb {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-jfqc7hmm0cb div {
  box-sizing: content-box;
}
/* generated by https://loading.io/ */





.container {
  width: 30px;
  height: 30px;
  position: relative;
}

.container.loading6 {
  -webkit-animation: rotation 1s infinite;
          animation: rotation 1s infinite;
}
.container.loading6 .shape {
  width: 12px;
  height: 12px;
  border-radius: 2px;
}
.container .shape {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 1px;
}
.container .shape.shape1 {
  left: 0;
  background-color: #1890FF;
}
.container .shape.shape2 {
  right: 0;
  background-color: #91CB74;
}
.container .shape.shape3 {
  bottom: 0;
  background-color: #FAC858;
}
.container .shape.shape4 {
  bottom: 0;
  right: 0;
  background-color: #EE6666;
}


.loading6 .shape1 {
  -webkit-animation: animation6shape1 2s linear 0s infinite normal;
          animation: animation6shape1 2s linear 0s infinite normal;
}

@-webkit-keyframes animation6shape1 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  25% {
    -webkit-transform: translate(0, 18px);
            transform: translate(0, 18px);
  }
  50% {
    -webkit-transform: translate(18px, 18px);
            transform: translate(18px, 18px);
  }
  75% {
    -webkit-transform: translate(18px, 0);
            transform: translate(18px, 0);
  }
}

@keyframes animation6shape1 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  25% {
    -webkit-transform: translate(0, 18px);
            transform: translate(0, 18px);
  }
  50% {
    -webkit-transform: translate(18px, 18px);
            transform: translate(18px, 18px);
  }
  75% {
    -webkit-transform: translate(18px, 0);
            transform: translate(18px, 0);
  }
}
.loading6 .shape2 {
  -webkit-animation: animation6shape2 2s linear 0s infinite normal;
          animation: animation6shape2 2s linear 0s infinite normal;
}

@-webkit-keyframes animation6shape2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  25% {
    -webkit-transform: translate(-18px, 0);
            transform: translate(-18px, 0);
  }
  50% {
    -webkit-transform: translate(-18px, 18px);
            transform: translate(-18px, 18px);
  }
  75% {
    -webkit-transform: translate(0, 18px);
            transform: translate(0, 18px);
  }
}

@keyframes animation6shape2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  25% {
    -webkit-transform: translate(-18px, 0);
            transform: translate(-18px, 0);
  }
  50% {
    -webkit-transform: translate(-18px, 18px);
            transform: translate(-18px, 18px);
  }
  75% {
    -webkit-transform: translate(0, 18px);
            transform: translate(0, 18px);
  }
}
.loading6 .shape3 {
  -webkit-animation: animation6shape3 2s linear 0s infinite normal;
          animation: animation6shape3 2s linear 0s infinite normal;
}

@-webkit-keyframes animation6shape3 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  25% {
    -webkit-transform: translate(18px, 0);
            transform: translate(18px, 0);
  }
  50% {
    -webkit-transform: translate(18px, -18px);
            transform: translate(18px, -18px);
  }
  75% {
    -webkit-transform: translate(0, -18px);
            transform: translate(0, -18px);
  }
}

@keyframes animation6shape3 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  25% {
    -webkit-transform: translate(18px, 0);
            transform: translate(18px, 0);
  }
  50% {
    -webkit-transform: translate(18px, -18px);
            transform: translate(18px, -18px);
  }
  75% {
    -webkit-transform: translate(0, -18px);
            transform: translate(0, -18px);
  }
}
.loading6 .shape4 {
  -webkit-animation: animation6shape4 2s linear 0s infinite normal;
          animation: animation6shape4 2s linear 0s infinite normal;
}

@-webkit-keyframes animation6shape4 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  25% {
    -webkit-transform: translate(0, -18px);
            transform: translate(0, -18px);
  }
  50% {
    -webkit-transform: translate(-18px, -18px);
            transform: translate(-18px, -18px);
  }
  75% {
    -webkit-transform: translate(-18px, 0);
            transform: translate(-18px, 0);
  }
}

@keyframes animation6shape4 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  25% {
    -webkit-transform: translate(0, -18px);
            transform: translate(0, -18px);
  }
  50% {
    -webkit-transform: translate(-18px, -18px);
            transform: translate(-18px, -18px);
  }
  75% {
    -webkit-transform: translate(-18px, 0);
            transform: translate(-18px, 0);
  }
}
</style>