import Vue from 'vue'
import VueRouter from 'vue-router'
import { getItem } from '../utils/storage'

// 这一段方法是 为了防止当前选中了路由在点击当前路由会报错的问题
let originPush = VueRouter.prototype.push;  //备份原push方法
VueRouter.prototype.push = function (location, resolve, reject) {
  if (resolve && reject) {    //如果传了回调函数，直接使用
    originPush.call(this, location, resolve, reject);
  } else {                     //如果没有传回调函数，手动添加
    originPush.call(this, location, () => { }, () => { });
  }
}

Vue.use(VueRouter)

const routes = [
  {
    path:'/',
    redirect:'/login',
    component:() =>import('../views/Login.vue')//路由懒加载写法
},
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
  },
  {
   
    path:'/home',
    name:'chrome',
    redirect: '/welcome',
   
    component: () => import('@/views/Home.vue'),
    children:[{path:'/welcome',component: () => import('../components/welcome/welcome.vue')},
     {
        meta: { name: '日志即周计划' },
        path: '/gosplan',
        component: () => import('../components/RoutPath/gosplan.vue'),
      },
      {
        name:'info',
        meta: { name: '用户管理' ,keepAlive:true},
        path: '/user',
        component: () => import('../components/RoutPath/user.vue'),
      },
      {
        meta: { name: '参数设置',keepAlive:true },
        path: '/param',
        component: () => import('../components/RoutPath/param.vue'),
      },
      {
        name:'Rolespage',
        meta: { name: '角色管理',keepAlive:true },
        path: '/Roles',
        component: () => import('../components/RoutPath/Rolespage.vue'),
      },
      {
        name:'MenuPage',
        meta: { name: '菜单管理',keepAlive:true },
        path: '/menupath',
        component: () => import('../components/RoutPath/menupathPage.vue'),
      },
      {
        name:'ModifyRoute',
        meta: { name: '修改单管理', keepAlive:true },
        path: '/modify',
        component: () => import('../components/RoutPath/modify.vue'),
      }
  ],
  },
  {
    path: '/:catchAll(.*)',
    name: 'not-found',
    component: () => import('../components/RoutPath/NotFound.vue'),
  }
]


const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

//全局前置路由，配合浏览器localStorage进行鉴权操作
router.beforeEach((to, from, next) => {
  document.title = adminName
 if(to.path=='/login') return next()

 const token = getItem("token")
 if (!token) return next('/login')

 next()
  

})

export default router
