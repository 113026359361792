
const daochuAll = {
  generateChineseName() {
    // 姓氏列表
    const surnames = ["赵", "钱", "孙", "李", "周", "吴", "郑", "王", "冯", "陈", "楮", "卫", "蒋", "沈", "韩", "杨", "朱", "秦", "尤", "许", "何", "吕", "施", "张", "孔", "曹", "严", "华", "金", "魏", "陶", "姜", "戚", "谢", "邹", "喻", "柏", "水", "窦", "章", "云", "苏", "潘", "葛", "奚", "范", "彭", "郎", "鲁", "韦", "昌", "马", "苗", "凤", "花", "方", "俞", "任", "袁", "柳", "丰", "鲍", "史", "唐", "费", "岑", "薛", "雷", "贺", "倪", "汤", "滕", "殷", "罗", "毕", "郝", "邬", "安", "常", "乐", "于", "时", "傅", "皮", "卞", "齐", "康", "伍", "余", "元", "卜", "顾", "孟", "平", "黄", "和", "穆", "萧", "尹"];
    // 名字部分
    const names = [
      "伟", "芳", "娜", "秀英", "敏", "静", "丽", "强", "磊", "洋", "艳", "勇", "艳华", "文华", "明", "亮", "璐", "杰", "秀兰", "云", "霞", "辉", "军", "玲", "桂英", "莉", "红", "平", "刚", "桂兰", "丹", "冬梅", "建华", "欣", "建国", "玉兰", "欢", "建平", "海燕", "淑珍", "翠", "晶", "本凤", "安琪", "常宁", "姣", "南京", "雯", "蓉", "肖", "蔷", "朝阳", "伊", "浩", "志", "希", "敏儿", "梦", "晨", "嘉欣", "佳", "园", "碧", "滢", "榕", "乐意", "鑫", "逸", "雅", "可心"
    ];
    // 随机选择一个姓氏
    const randomSurname = surnames[Math.floor(Math.random() * surnames.length)];
    // 随机选择一个名字部分
    const randomName = names[Math.floor(Math.random() * names.length)];
    // 组合生成中文名字
    const chineseName = randomSurname + randomName;
  
    return chineseName;
  },

  // 随机手机号
  generateRandomChinesePhoneNumber() {
    const prefixList = ["13", "15", "17", "18", "19"];
    const randomPrefix = prefixList[Math.floor(Math.random() * prefixList.length)];
    
    let phoneNumber = randomPrefix;
    for (let i = 0; i < 9; i++) {
      phoneNumber += Math.floor(Math.random() * 10);
    }
    
    return phoneNumber;
  },
  // 随机用户名
  generateUsername() {
    const adjectives = ['Happy', 'Silly', 'Clever', 'Brave', 'Witty', 'Kind', 'Lucky', 'Gentle', 'Wild', 'Crazy'];
    const nouns = ['Cat', 'Dog', 'Tiger', 'Lion', 'Elephant', 'Monkey', 'Dolphin', 'Bear', 'Bird', 'Turtle'];
  
    const randomAdjective = adjectives[Math.floor(Math.random() * adjectives.length)];
    const randomNoun = nouns[Math.floor(Math.random() * nouns.length)];
  
    return randomAdjective + randomNoun;
  },
//随机生成公司名字方法
generateChineseCompanyName() {
  const adjectives = ['创新', '全球', '动力', '创意', '科技', '智能', '战略', '提升', '敏捷', '未来'];
  const nouns = ['解决方案', '创业', '实验室', '科技', '系统', '企业', '集团', '合作伙伴', '创新', '数字'];

  const randomAdjective = adjectives[Math.floor(Math.random() * adjectives.length)];
  const randomNoun = nouns[Math.floor(Math.random() * nouns.length)];

  return randomAdjective + randomNoun;
},
//随机生成邮箱号
generateRandomEmail() {
  const domains = ['gmail.com', 'yahoo.com', 'hotmail.com', 'outlook.com', 'aol.com', 'icloud.com', 'mail.com', 'protonmail.com', 'zoho.com', 'yandex.com'];
  const randomDomain = domains[Math.floor(Math.random() * domains.length)];
  const usernameLength = Math.floor(Math.random() * 10) + 5; // 随机生成用户名长度，范围为 5 到 14

  let username = '';
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < usernameLength; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    username += characters.charAt(randomIndex);
  }

  return username + '@' + randomDomain;
},

//随机生成年月日
generateRandomDate() {
  const startYear = 1970; // 开始年份
  const endYear = 2023; // 结束年份

  const year = Math.floor(Math.random() * (endYear - startYear + 1)) + startYear;
  const month = String(Math.floor(Math.random() * 12) + 1).padStart(2, '0'); // 生成 01 到 12 之间的随机月份
  const day = String(Math.floor(Math.random() * 28) + 1).padStart(2, '0'); // 生成 01 到 28 之间的随机日期

  return `${year}-${month}-${day}`;
},
//随机生成男女性别
generateRandomGender() {
  const genders = ['男', '女']; // 可选的性别

  const randomIndex = Math.floor(Math.random() * genders.length);
  const gender = genders[randomIndex];

  return gender;
},

// 改变数组下标
changeArrayIndex(arr, oldIndex, newIndex) {
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
},
// 根据id来匹配数组的下标
findIndexById(arr, id) {
  // console.log(findIndexById(myArray, 2)); // 输出: 1  使用方法
  return arr.findIndex(item => item.id === id);
},
// 移除数组前面一位和最后一位
removeFirstAndLast(arr) {
  arr.shift(); // 移除第一个元素
  arr.pop(); // 移除最后一个元素
  return arr;
},
downGo(fieldData,index) {
 
  if(index!=fieldData.length-1){

      fieldData[index] = fieldData.splice(index+1, 1, fieldData[index])[0];

  }else{

      fieldData.unshift( fieldData.splice(index,1)[0]);

  }

},

 
 

}
export default daochuAll