<template>
  <div id="app">

    <router-view />
  </div>
</template>

<script>
export default {
  mounted(){
    console.log(this.$route);
  }
}
</script>



<style lang="scss">
* {
  margin: 0;
  padding: 0;
}


</style>

 
