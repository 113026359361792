 

export default {
    state: {
      routeNameList:[]
    },
    mutations: {
    //  存储路由信息
    // 存储路由信息
       pushRouterName(state,data){
        console.log('调用了');
        state.routeNameList=data
       }
    }
}