

export default {
  // 全局的表格表头
  state: {
    headList: [
      // {
      //   id:0,
      //   width: '40',
      //   label: "",
      //   show_ite: true, //显示列
      //   hide_ite: true,//冻结列
      //   px_cit: false,//排序
      //   right_ite: 'left',//方向，
      //   type:'selection',
      //   disabled:true,//开关禁用 这个是选择框的
      // },
      {
        id:1,
        width: '',
        label: "公司",
        prop: "company",
        show_ite: true, //显示列
        hide_ite: false,//冻结列
        px_cit: false,//排序
        right_ite: 'left',//方向，
       
         
      },
      {
        id:2,
        width: '',
        label: "用户名",
        prop: "name",
        show_ite: true, //显示列
        hide_ite: false,//冻结列
        px_cit: false,//排序
        right_ite: 'left',//方向
     
      },
      {
        id:3,
        width: '',

        label: "姓名",
        prop: "address",
        show_ite: true, //显示列
        hide_ite: false,//冻结列
        px_cit: false,//排序
        right_ite: 'left',//方向
    
      },
      {
        id:4,
        width: '',

        label: "手机号",
        prop: "mobile",
        show_ite: true, //显示列
        hide_ite: false,//冻结列
        px_cit: false,//排序
        right_ite: 'left',//方向
     
      },
      {
        id:5,
        width: '',

        label: "性别",
        prop: "sex",
        show_ite: true, //显示列
        hide_ite: false,//冻结列
        px_cit: false,//排序
        right_ite: 'left',//方向
       
      },
      {
        id:6,
        width: '',

        label: "邮箱",
        prop: "zipcode",
        show_ite: true, //显示列
        hide_ite: false,//冻结列
        px_cit: false,//排序
        right_ite: 'left',//方向
    
      },
      {
        id:7,
        width: '',

        label: "修改时间",
        prop: "date",
        show_ite: true, //显示列
        hide_ite: false,//冻结列
        px_cit: false,//排序
        right_ite: 'left',//方向
    
      },
      {
        id:8,
        width: '',
        label: "修改1",
        prop: "scag",
        show_ite: true, //显示列
        hide_ite: false,//冻结列
        px_cit: false,//排序
        right_ite: 'left',//方向
  
      },
      {
        id:9,
        width: '',
        label: "修改2",
        prop: "scagx",
        show_ite: true, //显示列
        hide_ite: false,//冻结列
        px_cit: false,//排序
        right_ite: 'left',//方向
    
      },
      // {
      //   id:10,
      //   width: '',
      //   label: "操作",
      //   prop: "scagz",
      //   show_ite: true, //显示列
      //   hide_ite: false,//冻结列
      //   px_cit: false,//排序
      //   right_ite: 'center',//方向
      //   type:'operate',
      //   disabled:true,//开关禁用 这个是操作狂的
     
      // },
 
    ]
  },
  mutations: {
    // 更改数组里面的显示方法
    ChangeTable(state,data){
      // state.headList=data
      state.headList=[]
      setTimeout(()=>{
       
        state.headList=data
      },300)
    console.log(state.headList,'state.headList');
    },
    // 根据id来删除这一项数据

  }
}