/*
 * @Author: μ薇 624331767@qq.com
 * @Date: 2022-09-21 08:53:57
 * @LastEditors: μ薇 624331767@qq.com
 * @LastEditTime: 2022-11-05 10:07:07
 * @FilePath: \wechatapplet_YYDS\utils\storage.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// 封装本地缓存请求模块


// 存储数据
export const setItem = (key, value) => {
	// 将数组、对象类型的数据转换为 JSON 格式字符串进行存储
	if (typeof value === 'object') {
		value = JSON.stringify(value)
	}
	// uni.setStorageSync(key, value);
	window.localStorage.setItem(key,value)
}


/**
 * 获取数据
 */
export const getItem = key => {
	const data = window.localStorage.getItem(key)
	try {
		return JSON.parse(data)
	} catch (err) {
		return data
	}
}

/**
 * 删除数据
 */
export const removeItem = key => {
	window.localStorage.removeItem(key)
}

// 清除所有缓存
export const deleteArr =()=>{
	window.localStorage.clear()
	window.sessionStorage.clear()
}
