import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './utils/element'
import 'element-ui/lib/theme-chalk/index.css';
 import './assets/public.css'
 import loading from './utils/loading.js'
 import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)
 Vue.use(loading)
Vue.config.productionTip = false
 

// 正式环境去除打印
if (process.env.NODE_ENV === 'production') {
  if (window) {
    window.console.log = function () {};
  }
}



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
