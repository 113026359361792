import Vue from 'vue'
import Vuex from 'vuex'
import { FormItem } from "element-ui"
import routeName from './routeName'
import Publicheader from './Publicheader'
import daochuAll from '@/utils/mock'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isCollapse: false,//控制菜单显示收齐
    tabList: [],//面包屑数据
    senteList: [],
    routeList:[],//路由列表
    ketList:[],
    tableHeaderList:[] ,//表格数据每一列宽度
  },
  getters: {
  },
  mutations: {
    // 修改菜单展开收起方法
    collapseMesu(state) {
      state.isCollapse = !state.isCollapse
    },
    // 修改面包屑数据
    selectMenu(state, data) {
      state.senteList.push(data)
      console.log(state, data, 'vuexs');
      // 判断添加数据是否一样
      // if (FormItem.name !== 'home') {
      //   const index = state.tabList.findIndex(item => item.name === data.name)
      //   if (index === -1) {
      //     state.tabList.push(data)
      //     window.sessionStorage.setItem('tag', JSON.stringify(state.tabList))
      //   }
      // }
    },
    setListtab(state, data) {
      const index = state.senteList.findIndex(item => item.title === data.title)
      if (index === -1) {
        state.senteList.push(data)
        window.sessionStorage.setItem('tag', JSON.stringify(state.senteList))
      }
      // 是否需要缓存的判断 如果是缓存数组里面没有就是直接push
      state.senteList.forEach((item,index)=>{
        if(!state.ketList.includes(item.pathName)){
            state.ketList.push(item.pathName)
        }
      })

    },
    // 删除页签
    removeTag(state, item) {
      let index = state.senteList.findIndex(val => val.title === item.title)
      console.log(index, 'index',item);
      state.senteList.splice(index, 1)
      window.sessionStorage.setItem('tag', JSON.stringify(state.senteList)) //这里是删除了也得把本地的数据删除掉,不然一刷新又是有的

      // 这里是判断路由名称和缓存路由的名称是否一样 一样的就拿他的坐标用来删除
      let cindex =state.ketList.findIndex(val=>val==item.pathName)
      state.ketList.splice(cindex, 1)
    },
    deleteList(state, data) {
      state.senteList = data
    },
    // 删除指定的tag数据
    closeTag(state, item) {
      let index = state.tabList.findIndex(val => val.name === item.name)
      state.tabList.splice(index, 1)
    },
    stateInit(state, data) {
      state.tabList = data
    },

    // 添加表格数据数据方法
    tableheaderlist(state,data){
      state.tableHeaderList=data
      console.log(state.tableHeaderList,'state.tableHeaderList');
    }

  },
  actions: {
  },
  modules: {
    routeName,
    Publicheader
  }
})
